// @ts-ignore
import styled from 'styled-components';
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";

function OrderShortSummary(props: {orderedItems: any, onGoToOrder: any}) {
    const items = props.orderedItems;
    return <div style={{boxShadow: "0px -4px 24px 0px rgba(0, 0, 0, 1)", backgroundColor: "rgba(176,12,4,0.75)", bottom: 0, position: "fixed", left: 0, right: 0}}>
        <div style={{margin: "0 auto", width: "100%", maxWidth: "1000px"}}>
            {items && items.length > 0 && <Summary orderedItems={items}/> }
        </div>
    </div>
}

function Summary(props: {orderedItems: any}) {
    const items = props.orderedItems;
        return <>
            <SummaryHolder>
                {/*<SummaryTextHolder>*/}
                {/*    <SummaryText>*/}
                {/*        Zamawianych: <SummaryBoldSpan>{items.reduce(function(sum: any, item: { quantity: any; }) {return sum + item.quantity;}, 0)}</SummaryBoldSpan>*/}
                {/*    </SummaryText>*/}
                {/*</SummaryTextHolder>*/}
                <SummaryTextHolder>
                    <SummaryText>
                        Twoje zamówienie: <SummaryBoldSpan>{items.reduce(function(sum: any, item: { quantity: number; product: any; }) {return sum + (item.product.price * item.quantity);}, 0).toFixed(2)}&nbsp;zł</SummaryBoldSpan>
                    </SummaryText>
                </SummaryTextHolder>
                <SummaryButtonHolder>
                    <SummaryButton>
                        Zrealizuj zamówienie <FontAwesomeIcon size="xl" icon={faArrowRight} />
                    </SummaryButton>
                </SummaryButtonHolder>
            </SummaryHolder>
        </>
    }

    const SummaryHolder = styled.div`
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-items: center;
    `;

const SummaryTextHolder = styled.div`
      flex: 1;
    `;

const SummaryText = styled.div`
  color: #ffffff;
  font-weight: 600;
  font-size: 1.2rem;
  text-align: center;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
  padding: 4px;
`;

const SummaryButtonHolder = styled.div`
  flex: 1;
`;

const SummaryButton = styled.button`
  background-color: rgba(252, 252, 252, 1);
  color: rgba(176,12,4,100);
  font-size: 1.2rem;
  border: none;
  width: 100%;
  border-radius: 0;
  outline: none;
  font-weight: 800;
  text-transform: uppercase;
  padding: 4px;
`;

const SummaryBoldSpan = styled.span`
  font-weight: 800;
`;

export default OrderShortSummary;