// @ts-ignore
import styled from "styled-components";

const ContentContainer = styled.div`
  width: 100%;
  margin-top: ${(props: { marginTop: any; }) => props.marginTop + 'px'};
`;

export default ContentContainer;

