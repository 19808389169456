// @ts-ignore
import styled from "styled-components";

const Layoutholder = styled.div`
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

export default Layoutholder;
