// @ts-ignore
import styled from "styled-components";

const Navbar = styled.div`
  width: 100%;
  text-align: right;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 10;
`;

export default Navbar;

