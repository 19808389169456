// @ts-ignore
import styled from "styled-components";

const Header = styled.div`
  width: 100%;
  position: fixed;
  background-color: #000000;
  z-index: 100;
  padding-bottom: 8px;
  -webkit-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 1);
  box-shadow: 0px 0px 16px 4px rgba(0, 0, 0, 1);
`;

export default Header;

