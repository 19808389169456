import React, {useEffect, useState} from 'react';
import './App.css';
import Layout from "./components/layout/layout";
import Wizard from "./pages/Wizard";
import getProductsData from "./services/ProductsDataProvider"
import ProductCard from "./components/ProductCard";
import OrderShortSummary from "./components/OrderShortSummary";
// @ts-ignore
import styled from "styled-components";
import backgroundImage from './assets/images/pizza-burger-background.jpg'

function App() {

  const [selectedProduct, setSelectedProduct] = useState<any>(null)
  const [order, setOrder] = useState([])

  function onWizardChange(changed: any) {
    setSelectedProduct(changed)
  }

  function onOrderClick(quantity: number) {
      // @ts-ignore
      setOrder(order => [...order,{quantity: quantity, product: selectedProduct}] );
      console.log(order)
  }

  function onGoToOrder() {
      alert('Work in progress :)');
  }

  useEffect(()=>{
      setTimeout(
          function() {
              var body = document.body,
                  html = document.documentElement;
              var height = Math.max( body.scrollHeight, body.offsetHeight,
                  html.clientHeight, html.scrollHeight, html.offsetHeight );
              window.scrollTo(500, height);
          }, 500);
  })

  return (
      <BackgroundHolder>
          <BackgroundMaskHolder>
    <Layout>

      <Wizard onChange={onWizardChange} root={0}></Wizard>

      { selectedProduct &&
          <ProductCard product={selectedProduct} onOrderClick={onOrderClick}></ProductCard>
      }

        {
            order.length > 0 &&
            <OrderShortSummary orderedItems={order} onGoToOrder={onGoToOrder} />
        }

      <div style={{height: 40}}></div>

    </Layout>
          </BackgroundMaskHolder>
      </BackgroundHolder>
  );
}

const BackgroundHolder = styled.div`
  width: 100%;
  height: 200%;
  background:url(${backgroundImage});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const BackgroundMaskHolder = styled.div`
  width: 100%;
  height: 200%;
  background-color: rgba(0,0,0,0.8);
`


export default App;
