// @ts-ignore
const productsData = [
    {id: 1, productName: 'Pizza', type: 'category', subtitle: 'Włoskie pizze', image: 'pizza.jpg', components: [], description: 'Pyszna pizza w stylu włoskim.', parentId: 0},
    {id: 2, productName: 'Burgery', type: 'category', subtitle: 'Amerykańskie burgery', image: 'burger.jpg', components: [], description: 'Najlepsze amerykańskie burgery', parentId: 0},
    {id: 3, productName: 'Dodatki', type: 'category', subtitle: 'Pyszne dodatki', image: 'dodatki.jpg', components: [], description: 'Nasze najlepsze dodatki', parentId: 0},
    {id: 4, productName: 'Napoje', type: 'category', subtitle: 'Najlepsze napoje', image: 'napoje.jpg', components: [], description: 'Napoje', parentId: 0},
    {id: 11, productName: 'Margherita', type: 'product', subtitle: 'krótki tekst', image: 'pizza_1.jpg', components: ['sos', 'ser', 'szynka', 'pieczarki'], description: 'Przykładowy opis pizzy', parentId: 1, price: 24.90},
    {id: 12, productName: 'Marinara', type: 'product', subtitle: 'krótki tekst', image: 'pizza_2.jpg', components: ['sos', 'ser'], description: 'Przykładowy opis', parentId: 1, price: 24.90},
    {id: 14, productName: 'Prosciutto e funghi', type: 'product', subtitle: 'krótki tekst', image: 'pizza_3.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 24.90},
    {id: 15, productName: 'Quattro Stagioni', type: 'product', subtitle: 'krótki tekst', image: 'pizza_4.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 25.90},
    {id: 16, productName: 'Capricciosa', type: 'product', subtitle: 'krótki tekst', image: 'pizza_5.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 26.90},
    {id: 17, productName: 'Quattro Formaggi', type: 'product', subtitle: 'krótki tekst', image: 'pizza_6.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 27.90},
    {id: 18, productName: 'Ortolana', type: 'product', subtitle: 'krótki tekst', image: 'pizza_7.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 28.90},
    {id: 19, productName: 'Diavola', type: 'product', subtitle: 'krótki tekst', image: 'pizza_8.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 29.90},
    {id: 190, productName: 'Boscaiola', type: 'product', subtitle: 'krótki tekst', image: 'pizza_2.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 30.90},
    {id: 191, productName: 'Frutti di Mare', type: 'product', subtitle: 'krótki tekst', image: 'pizza_3.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 1, price: 31.90},
    {id: 21, productName: 'Burger 1', type: 'product', subtitle: 'krótki tekst', image: 'burger_1.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 2, price: 21.90},
    {id: 22, productName: 'Burger 2', type: 'product', subtitle: 'krótki tekst', image: 'burger_2.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 2, price: 22.90},
    {id: 23, productName: 'Burger 3', type: 'product', subtitle: 'krótki tekst', image: 'burger_3.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 2, price: 23.90},
    {id: 24, productName: 'Burger 4', type: 'product', subtitle: 'krótki tekst', image: 'burger_4.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 2, price: 24.90},
    {id: 25, productName: 'Burger 5', type: 'product', subtitle: 'krótki tekst', image: 'burger_5.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 2, price: 25.90},
    {id: 26, productName: 'Burger 6', type: 'product', subtitle: 'krótki tekst', image: 'burger_6.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 2, price: 26.90},
    {id: 31, productName: 'Frytki', type: 'product', subtitle: 'krótki tekst', image: 'frygki.jpg', components: ['Składmik 1', 'Składnik 2', 'Składnik 3', 'Składmin 4'], description: 'Przykładowy opis', parentId: 3, price: 24.90},
    {id: 32, productName: 'Frytki z batatów', type: 'product', subtitle: 'krótki tekst', image: 'bataty.jpg', components: [], description: 'Przykładowy opis', parentId: 3, price: 24.90},
    {id: 33, productName: 'Krążki cebulowe', type: 'product', subtitle: 'krótki tekst', image: 'onion-rings.jpg', components: [], description: 'Przykładowy opis', parentId: 3, price: 24.90},
    {id: 34, productName: 'Sosy', type: 'category', subtitle: 'Wybierz pyszne sosy!', image: 'sos.jpg', components: [], description: 'Przykładowy opis', parentId: 3},
    {id: 36, productName: 'Sałatka ', type: 'product', subtitle: 'krótki tekst', image: 'salatka.jpg', components: [], description: 'Przykładowy opis', parentId: 3, price: 10},
    {id: 41, productName: 'Cola', type: 'product', subtitle: 'krótki tekst', image: 'cola.jpg', components: [], description: 'Przykładowy opis', parentId: 4, price: 10},
    {id: 42, productName: 'Fanta', type: 'product', subtitle: 'krótki tekst', image: 'fanta.jpg', components: [], description: 'Przykładowy opis', parentId: 4, price: 10},
    {id: 43, productName: 'Srite', type: 'product', subtitle: 'krótki tekst', image: 'sprite.jpg', components: [], description: 'Przykładowy opis', parentId: 4, price: 10},
    {id: 44, productName: 'Lemoniada', type: 'product', subtitle: 'krótki tekst', image: 'lemoniada.jpg', components: [], description: 'Przykładowy opis', parentId: 4, price: 10},
    {id: 45, productName: 'Woda mineralna', type: 'product', subtitle: 'krótki tekst', image: 'woda.jpg', components: [], description: 'Przykładowy opis', parentId: 4, price: 10},
    {id: 46, productName: 'Napój energetyczny', type: 'product', subtitle: 'krótki tekst', image: 'energetyczny.jpg', components: [], description: 'Przykładowy opis', parentId: 4, price: 10},
    {id: 51, productName: 'Sos pomidorowy', type: 'product', subtitle: 'krótki tekst', image: 'sos-pomidorowy.jpg', components: [], description: 'Przykładowy opis', parentId: 34, price: 3},
    {id: 52, productName: 'Sos czosnkowy', type: 'product', subtitle: 'krótki tekst', image: 'sos-czosnkowy.jpg', components: [], description: 'Przykładowy opis', parentId: 34, price: 3},
    {id: 53, productName: 'Sos barbecue', type: 'product', subtitle: 'krótki tekst', image: 'sos-barbecue.jpg', components: [], description: 'Przykładowy opis', parentId: 34, price: 3},
    {id: 54, productName: 'Sos ostry', type: 'product', subtitle: 'krótki tekst', image: 'sos-ostry.jpg', components: [], description: 'Przykładowy opis', parentId: 34, price: 3},
    {id: 55, productName: 'Sos aioli', type: 'product', subtitle: 'krótki tekst', image: 'sos-aioli.jpg', components: [], description: 'Przykładowy opis', parentId: 34, price: 3},
    {id: 56, productName: 'Oliwa', type: 'product', subtitle: 'krótki tekst', image: 'oliwa.jpg', components: [], description: 'Przykładowy opis', parentId: 34, price: 3},
];

function getData(parentId = 0) {
    return productsData.filter((item) => item.parentId === parentId)
}

export default getData;