import React, {useEffect, useState} from "react";
import Navbar from "./navbar";
import Layoutholder from "./layoutholder";
import Header from "./header";
import Logoimage from "./logoimage";
import logo from '../../assets/images/rebelia.jpg';
import Bottom from "./bottom";
import Footer from "./footer";
import ContentContainer from "./content";

function Layout({children} : {children: any}) {
    const [height, setHeight] = useState(0);
    const [windowHeight, setWindowHeight] = useState();
    const [currentScroll, setCurrentScroll] = useState();
    // @ts-ignore
    useEffect( () => {
        const handleScroll = (event: any) => {
            // @ts-ignore
            setWindowHeight(window.innerHeight)
            // @ts-ignore
            setCurrentScroll(window.scrollY)
            // @ts-ignore
            let heightToSet = (window.innerHeight / 2) - window.scrollY;
            if (heightToSet < 50)
                heightToSet = 50;
            setHeight(heightToSet)
        };
        // @ts-ignore
        handleScroll()
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <>
            <Layoutholder>
                <Navbar>
                    tutaj będą opcje użytkownika
                </Navbar>
                <Header>
                    <Logoimage height={height} src={logo}/>
                </Header>
                <ContentContainer marginTop={height}>
                    {children}
                </ContentContainer>
                <Bottom>
                    
                </Bottom>
                <Footer>
                </Footer>
            </Layoutholder>
        </>
    );
}

export default Layout;
