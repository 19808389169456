// @ts-ignore
import styled from 'styled-components';
import {useState} from "react";

function OrderButtonGroup (props: {price: any; value: number; onChange: any; onOrder: any;}) {

    let [value, setValue] = useState(props.value)

    function onClickPlus() {
        setValue(value+1)
    }

    function onClickMinus() {
        if (value > 1)
            setValue(value-1)
    }

    function onClickOrder() {
        props.onOrder(value)
    }

    return <Holder>
        <PriceHolder>
            <PriceLabel>{(props.price * value).toFixed(2)} zł</PriceLabel>
        </PriceHolder>
        <QuantityButtonsHolder>
            <MinusHolder>
                <MinusButton onClick={onClickMinus}>-</MinusButton>
            </MinusHolder>
            <QuantityHolder>
                <QuantityInput type="text" value={value} />
            </QuantityHolder>
            <PlusHolder>
                <PlusButton onClick={onClickPlus}>+</PlusButton>
            </PlusHolder>
        </QuantityButtonsHolder>
        <OrderButtonHolder>
            <OrderButton onClick={onClickOrder}>ZAMÓW</OrderButton>
        </OrderButtonHolder>
    </Holder>
}


const Holder = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin: 8px 0;
`;

const PriceHolder = styled.div`
  padding: 4px;
`;

const PriceLabel = styled.div`
    font-size: 1.5rem;
    color: #ffffff;
  font-weight: bold;
  width: 160px;
  text-align: center;
  border: 2px#b00c04 dashed;
  border-radius: 5px;
`;

const QuantityButtonsHolder = styled.div`
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    width: 160px;
    margin-left: 8px;
    margin-right: 8px;
`;

const MinusHolder = styled.div`
padding: 4px;
`;
const QuantityHolder = styled.div`
  padding: 4px;
`;
const PlusHolder = styled.div`
  padding: 4px;
`;

const MinusButton = styled.button`
  background-color: inherit;
  color: #ffffff;
  border-color:#b00c04;
  font-size: 1.5rem;
  font-weight: bold;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  border-style: solid;
  padding: 0;
  text-align: center;
`;
const QuantityInput = styled.input`
  width: 50px;
  height: 28px;
  margin: 0;
  padding: 0;
  border: 2px#b00c04 solid;
  border-radius: 5px;
  background: none;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.5rem;
  text-align: center;
  outline: none;
`;
const PlusButton = styled.button`
  background-color: inherit;
  color: #ffffff;
  border-color:#b00c04;
  font-size: 1.5rem;
  font-weight: bold;
  width: 32px;
  height: 32px;
  border-radius: 4px;
  outline: none;
  border-style: solid;
  padding: 0;
  text-align: center;
`;


const OrderButtonHolder = styled.div`
  padding: 4px;
`;


const OrderButton = styled.button`
  background-color: rgba(252,252,252,1);
  color: rgba(176,12,4,100);
  font-size: 1.5rem;
  font-weight: bolder;
  height: 32px;
  border: none;
  width: 160px;
  border-radius: 4px;
  outline: none;`;

export default OrderButtonGroup;