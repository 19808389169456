import React, {useEffect, useState} from "react";
import {ScrollMenu, VisibilityContext} from "react-horizontal-scrolling-menu";
import getProductsData from "../services/ProductsDataProvider"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons'

type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

function Wizard(props: any) {

    const items = getProductsData(props.root)

    // NOTE: for drag by mouse
    const {dragStart, dragStop, dragMove, dragging} = useDrag();
    const handleDrag = ({scrollContainer}: scrollVisibilityApiType) => (
        ev: React.MouseEvent
    ) =>
        dragMove(ev, (posDiff) => {
            if (scrollContainer.current) {
                scrollContainer.current.scrollLeft += posDiff;
            }
        });


    const [selected, setSelected] = React.useState<string>("");

    const handleItemClick = (item: any) => () => {
        if (dragging) {
            return false;
        }
        setSelected(item.id);
        if (item.type === 'product') {
            props.onChange(item)
        } else {
            props.onChange(null)
        }
        setTimeout(
            function() {
                var body = document.body,
                    html = document.documentElement;
                var height = Math.max( body.scrollHeight, body.offsetHeight,
                    html.clientHeight, html.scrollHeight, html.offsetHeight );
                window.scrollTo(200, height);
            }, 0);

    };

    function isSelectedRootChild() {
        // @ts-ignore
        let foundItem = items.find((item) => item.id === selected)
        if (foundItem) {
            if (foundItem.parentId == props.root)
                return true;
        }
        return false;
    }

    if (!items.length)
        return <></>
    return (
        <>
            <div className={"wizard"+props.root} style={{paddingTop: "32px"}}>
                <div onMouseLeave={dragStop}>
                    <ScrollMenu
                        // LeftArrow={LeftArrow}
                        // RightArrow={RightArrow}
                        onWheel={onWheel}
                        onMouseDown={() => dragStart}
                        onMouseUp={() => dragStop}
                        onMouseMove={handleDrag}
                    >
                        {items.map((item: { id: any; productName: any; }) => (
                            <Card
                                item={item}
                                title={item.productName}
                                itemId={item.id} // NOTE: itemId is required for track items
                                key={item.id}
                                onClick={handleItemClick(item)}
                                selected={item.id === selected}
                            />
                        ))}
                    </ScrollMenu>
                </div>
            </div>


            { (selected && selected != "" && selected != props.root && isSelectedRootChild()) && <>
                <Wizard onChange={props.onChange} root={selected}></Wizard>
            </> }

        </>
    );

    function onWheel(apiObj: scrollVisibilityApiType, ev: React.WheelEvent): void {
        const isThouchpad = Math.abs(ev.deltaX) !== 0 || Math.abs(ev.deltaY) < 15;

        if (isThouchpad) {
            ev.stopPropagation();
            return;
        }

        if (ev.deltaY < 0) {
            apiObj.scrollNext();
        } else if (ev.deltaY > 0) {
            apiObj.scrollPrev();
        }
    }
}


export function Card({
                         item,
                         itemId,
                         selected,
                         onClick,
                         title
                     }: {
    item: any;
    itemId: string;
    selected: boolean;
    onClick: Function;
    title: string;
}) {
    const visibility = React.useContext(VisibilityContext);

    const visible = visibility.isItemVisible(itemId);

    const [hover, setHover] = useState(false);

    function onMouseEnter() {
        setHover(true)
    }
    function onMouseLeave() {
        setHover(false)
    }

    return (
        <div
            onClick={() => onClick()}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            role="button"
            style={{
                border: (selected || hover ? "2px#b00c04 "+(hover && !selected ? "dashed" : "solid") : "2px black solid"),
                boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.7)',
                borderRadius: "12px",
                display: "inline-block",
                margin: "0 10px",
                width: "180px",
                maxWidth: "60vw",
                userSelect: "none",
                position: "relative"
            }}
            tabIndex={0}
            className="card"
        >
            <div style={{borderBottomRightRadius: '12px', borderBottomLeftRadius: '12px', textShadow: "4px 4px 6px rgba(0, 0, 0, 1)", background: "linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,0.5) 30%, rgba(0,0,0,0.5) 100%)", width: "100%", bottom: 0, position: "absolute", padding: "16px 0", textAlign: "center"}}>
                <div style={{marginTop: "0", fontSize: "1.3rem", fontWeight: 800}}>{item.productName}</div>
                <div style={{marginTop: "8px", fontSize: "0.9rem", fontWeight: 700}}>{(item.price ? item.price + ' zł': item.subtitle)}</div>
                {/*<div style={{backgroundColor: visible ? "transparent" : "gray"}}>*/}
                {/*    visible: {JSON.stringify(visible)}*/}
                {/*</div>*/}
                {/*<div>selected: {JSON.stringify(!!selected)}</div>*/}
            </div>
            <div
                style={{
                    backgroundColor: selected ? "#b00c04" : "black",
                    backgroundImage: 'url(images/' + item.image + ')',
                    borderRadius: "12px",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    height: "200px",
                    maxHeight: "35vh"
                }}
            />
        </div>
    );
}


function Arrow({
                   children,
                   disabled,
                   onClick
               }: {
    children: React.ReactNode;
    disabled: boolean;
    onClick: VoidFunction;
}) {
    return (
        <button
            disabled={disabled}
            onClick={onClick}
            style={{
                backgroundColor: "inherit",
                color: "#b00c04",
                border: "none",
                outline: "none",
                cursor: "pointer",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                right: "1%",
                opacity: disabled ? "0" : "1",
                userSelect: "none"
            }}
        >
            {children}
        </button>
    );
}

function LeftArrow() {
    const {
        isFirstItemVisible,
        scrollPrev,
        visibleElements,
        initComplete
    } = React.useContext(VisibilityContext);

    const [disabled, setDisabled] = React.useState(
        !initComplete || (initComplete && isFirstItemVisible)
    );
    React.useEffect(() => {
        // NOTE: detect if whole component visible
        if (visibleElements.length) {
            setDisabled(isFirstItemVisible);
        }
    }, [isFirstItemVisible, visibleElements]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollPrev()}>
            <FontAwesomeIcon style={{margin: "2px", boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 1)', display: "block", backgroundColor: "#b00c04", borderRadius: "50%", color: "black", width: "20px", height: "20px", padding: "2px", opacity: 0.5}} size="xl" icon={faArrowLeft} />
        </Arrow>
    );
}

function RightArrow() {
    const {isLastItemVisible, scrollNext, visibleElements} = React.useContext(
        VisibilityContext
    );

    const [disabled, setDisabled] = React.useState(
        !visibleElements.length && isLastItemVisible
    );
    React.useEffect(() => {
        if (visibleElements.length) {
            setDisabled(isLastItemVisible);
        }
    }, [isLastItemVisible, visibleElements]);

    return (
        <Arrow disabled={disabled} onClick={() => scrollNext()}>
            <FontAwesomeIcon style={{margin: "2px", boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 1)', display: "block", backgroundColor: "#b00c04", borderRadius: "50%", color: "black", width: "20px", height: "20px", padding: "2px", opacity: "0.5"}} size="xl" icon={faArrowRight} />
        </Arrow>
    );
}


function useDrag() {
    const [clicked, setClicked] = React.useState(false);
    const [dragging, setDragging] = React.useState(false);
    const position = React.useRef(0);

    const dragStart = React.useCallback((ev: React.MouseEvent) => {
        position.current = ev.clientX;
        setClicked(true);
    }, []);

    const dragStop = React.useCallback(
        () =>
            // NOTE: need some delay so item under cursor won't be clicked
            window.requestAnimationFrame(() => {
                setDragging(false);
                setClicked(false);
            }),
        []
    );

    const dragMove = (ev: React.MouseEvent, cb: (posDif: number) => void) => {
        const newDiff = position.current - ev.clientX;

        const movedEnough = Math.abs(newDiff) > 5;

        if (clicked && movedEnough) {
            setDragging(true);
        }

        if (dragging && movedEnough) {
            position.current = ev.clientX;
            cb(newDiff);
        }
    };

    return {
        dragStart,
        dragStop,
        dragMove,
        dragging,
        position,
        setDragging
    };
}


export default Wizard