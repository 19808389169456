// @ts-ignore
import styled from 'styled-components';
import OrderButtonGroup from "./common/OrderButtonGroup";
import {isNumberObject} from "util/types";
import {useEffect} from "react";


function ProductCard(props: any) {

    let product = props.product;

    const onQuantityChange = (value: number) => {

    }

    const onOrder = (quantity: number) => {
        props.onOrderClick(quantity)
    }

    // useEffect(() => {
    //     var body = document.body,
    //         html = document.documentElement;
    //     var height = Math.max( body.scrollHeight, body.offsetHeight,
    //         html.clientHeight, html.scrollHeight, html.offsetHeight );
    //     window.scrollTo(0, height);
    // })


    return <ProductCardContainer>
        <ProductCardTitle>{product.productName}</ProductCardTitle>

        <ProductCardDetailsContainer>
            <ProductCardDescription>
                <ProductCardSubtitle>{product.subtitle}</ProductCardSubtitle>

                <p style={{textShadow: "2px 2px 4px rgba(0, 0, 0, 1)"}}>{product.description}</p></ProductCardDescription>
            <ProductCardImage>
                <img alt="" style={{boxShadow: '4px 4px 16px 0px rgba(0, 0, 0, 0.7)', height: 200, borderRadius: "12px"}} src={'./images/'+product.image} />
            </ProductCardImage>
            <ProductCardItemsList>
                <ProductCardComponentsListHeader>Składniki</ProductCardComponentsListHeader>
                <ProductCardComponentsList>
                    {product.components.map((item: any) => <ProductCardComponentsListItem>{item}</ProductCardComponentsListItem>)}
                </ProductCardComponentsList>
            </ProductCardItemsList>
        </ProductCardDetailsContainer>
        <TopOrderButtonsContainer>
            <OrderButtonGroup
                price={product.price}
                value={1}
                onChange={onQuantityChange}
                onOrder={onOrder}/>
        </TopOrderButtonsContainer>
    </ProductCardContainer>

}

const ProductCardContainer = styled.div`
  background-color: rgba(0,0,0,0.1);
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 24px;
  margin-bottom: 64px;
  padding: 8px 8px;
  padding-bottom: 24px;
  display: block;
  border-radius: 12px;
`;

const ProductCardTitle = styled.h1`
  text-align: center;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
`;

const ProductCardSubtitle = styled.h2`
  text-align: center;
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
`;

const ProductCardDetailsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: center;
  margin-top: 16px;
`;

const ProductCardImage = styled.div`
    flex: 40 1;
    text-align: center;
    padding: 5px;
    min-width: 200px;
`;

const ProductCardDescription = styled.div`
  flex: 40 1;
  text-align: center;
  padding: 5px;
  min-width: 200px;
`;

const ProductCardItemsList = styled.div`
  flex: 40 1;
  text-align: center;
  padding: 5px;
  min-width: 200px;

`;

const ProductCardComponentsListHeader = styled.h3`
  text-shadow: 4px 4px 6px rgba(0, 0, 0, 1);
`;

const ProductCardComponentsList = styled.ul`
  list-style: none;
  padding-left: 0;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
`;

const ProductCardComponentsListItem = styled.li`
  font-size: 1.1rem;
  line-height: 1-0%;
  display: inline-block;
  padding: 9px 8px;
`;

const TopOrderButtonsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: center;;
    justify-content: center;;
  opacity: 0.75;
`;

export default ProductCard;