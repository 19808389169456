// @ts-ignore
import styled from "styled-components";

const Bottom = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  text-align: center;
  background-color: #000000;
`;

export default Bottom;

