// @ts-ignore
import styled from "styled-components";


const Logoimage = styled.div`
  width: 100%;
  max-height: 50vh;
  top: 0;
  left: 0;
  text-align: center;
  position: fixed;
  background-color: #000000;
  height: ${(props: { height: number; src: string }) =>  props.height + 'px'};
`;

function LayoutLogoImage(props: { height: any, src: any; }) {
    return (
        <>
            <Logoimage height={props.height}>
                <img style={{maxHeight: "100%"}} src={props.src} />
            </Logoimage>
        </>
    );
}

export default LayoutLogoImage;

